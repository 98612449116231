import React, { createContext, useCallback, useMemo, useState } from "react";
import {
  ReplyClusterContext as RCC,
  Reply,
  ReqMetadata,
  ReplyClusterActions as RCA,
  ReplyClusterState as RCS,
} from "./types";
import {
  _getReplyContent,
  _getSenderInitials,
  getDefaultReplyClusterContext,
  getIsRescheduling,
  getReplyViewClusteringHistory,
} from "./helpers";
import { HttpFetchResult } from "../../../../hooks/http/fetch/types";
import { ReplyType } from "../../../../../../main-service/src/types/shared/replies";

export const ReplyClusterStateContext = createContext<RCC>(
  getDefaultReplyClusterContext()
);

export const useReplyCluster = (): RCC => {
  const [metadata, setMetadata] = useState<ReqMetadata>({
    isLoading: false,
    error: "",
    message: "",
  });
  const [reply, setReply] = useState<RCS["reply"]>(null);
  const [cluster, setCluster] = useState<RCS["cluster"]>(null);
  const [hint, setHint] = useState<RCS["hint"]>(null);
  const [suggestion, setSuggestion] = useState<RCS["suggestion"]>(null);
  const [assignedOn, setAssignedOn] = useState<RCS["assignedOn"]>(0);
  const [nextScheduleDate, setNextScheduleDate] =
    useState<RCS["nextScheduleDate"]>(null);
  const [showTranslated, setShowTranslated] =
    useState<RCS["showTranslated"]>(true);
  const [showOriginal, setShowOriginal] = useState<RCS["showOriginal"]>(false);

  const isAutoReply: RCS["isAutoReply"] = reply?.type === ReplyType.AUTOREPLY;

  const replyClusteringHistory: RCS["replyClusteringHistory"] = useMemo(
    () => getReplyViewClusteringHistory(reply?.reply_user_actions),
    [reply?.reply_user_actions]
  );

  const isRescheduling: RCS["isRescheduling"] = getIsRescheduling(
    replyClusteringHistory,
    isAutoReply
  );

  const setReplyAndMetadata = useCallback(
    (res: HttpFetchResult<Reply>) => {
      const { data, ...metadata } = res;
      setReply(data);
      setMetadata({ ...metadata, isLoading: false });
    },
    []
  );

  const resetRequestMetadata = useCallback(() => {
    setMetadata({
      isLoading: true,
      error: "",
      message: "",
    });
  }, []);

  const resetAssignedOnAndRequestMeta = useCallback(
    (res: HttpFetchResult<unknown>) => {
      const { data, ...meta } = res;
      setAssignedOn(0);
      setMetadata({ ...meta, isLoading: false });
    },
    []
  );

  const getReplyContent: RCA["getReplyContent"] = useCallback((): string => {
    return _getReplyContent(reply, showTranslated, showOriginal);
  }, [showTranslated, showOriginal, reply]);

  const getSenderInitials: RCA["getSenderInitials"] = useCallback(() => {
    return _getSenderInitials(
      reply?.sender?.first_name || "",
      reply?.sender?.last_name || ""
    );
  }, [reply?.sender]);

  return [
    {
      metadata,
      reply,
      suggestion,
      nextScheduleDate,
      showOriginal,
      showTranslated,
      cluster,
      hint,
      replyClusteringHistory,
      isAutoReply,
      isRescheduling,
      assignedOn,
    },
    {
      setMetadata,
      setAssignedOn,
      setReply,
      setSuggestion,
      getReplyContent,
      getSenderInitials,
      setCluster,
      setHint,
      setNextScheduleDate,
      resetAssignedOnAndRequestMeta,
      resetRequestMetadata,
      setReplyAndMetadata,
      setShowTranslated,
      setShowOriginal,
    },
  ];
};

export const ReplyClusterStateProvider = ({ children }) => {
  const value = useReplyCluster();
  return (
    <ReplyClusterStateContext.Provider value={value}>
      {children}
    </ReplyClusterStateContext.Provider>
  );
};
