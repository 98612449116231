import { ReplyType } from './replies';

export type PromptResultExample = {
  email: string;
  categorization: string;
};

export type PromptConfig = {
  objective: string;
  generalInstructions: string;
  formatInstructions: string;
  categories: ClusteringAISentiments[];
  examples: PromptResultExample[];
};

export enum ClusteringAISentiments {
  MEETING_REQUEST = '1. Positive - Meeting Request - Recipient requests a meeting or shows strong interest.',
  REFERRAL_IN_EMAIL = '2. Neutral - Referral In Email - Recipient refers you to a specific person in the email content, providing their name and/or contact details.',
  REFERRAL_IN_CC = '3. Neutral - Referral In CC - Recipient adds another person in the CC field and mentions it.',
  HAS_A_QUESTION = '4. Neutral - Has a Question - Recipient asks a question about the service being advertised.',
  CONTACT_LATER = '5. Neutral - Contact Later - Recipient asks to be contacted again at a specific future time.',
  NOT_RELEVANT_WITH_REASON = '6. Negative - Not Relevant with Reason -Recipient states the service is not relevant and provides very specific reasons.',
  NOT_RELEVANT_WITHOUT_REASON = '7. Negative - Not Relevant without Reason - Recipient states the service is not relevant without providing specific reasons',
  OTHER_SOLUTION_IN_PLACE = '8. Negative - Other Solutions in place - Recipient mentions they already have similar solutions or providers.',
  PLEASE_DELETE_MY_DATA_NOT_ANGRY = '9. Negative - Please Delete My Data(NOT Angry) - Recipient requests data deletion without legal threats.',
  PLEASE_DELETE_MY_DATA_ANGRY = '10. Negative - Please Delete MY Data (Angry) - Recipient requests data deletion and threatens legal action.',
  WHERE_DID_YOU_GET_MY_DATA_ANGRY = '11. Negative - Where Did You Get My Data (Angry) - Recipient asks about data source and threatens legal action.',
  WHERE_DID_YOU_GET_MY_DATA_NOT_ANGRY = '12. Negative - Where Did You Get My Data (NOT Angry) - Recipient asks about data source without legal threats.',
  AUTOREPLY = '13. Autoreply - Out of Office Autoreply - Automatic reply indicating the recipient is unavailable and specifies next availability.',
  BOUNCED = '14. Bounced - Email  Bounced - Email could not be delivered due to delivery failures.',
}

export enum E_ClusteringCategories {
  MEETING_REQUEST = 'Meeting Request',
  REFERRAL_IN_EMAIL = 'Referral in Email',
  REFERRAL_IN_CC = 'Referral in CC',
  HAS_A_QUESTION = 'Has a Question',
  CONTACT_LATER = 'Contact Later',
  NOT_RELEVANT_WITH_REASON = 'Not Relevant with Reason',
  NOT_RELEVANT_WITHOUT_REASON = 'Not Relevant without Reason',
  OTHER_SOLUTION_IN_PLACE = 'Other Solution in Place',
  PLEASE_DELETE_MY_DATA = 'Please Delete my Data',
  WHERE_DID_YOU_GET_MY_DATA = 'Where did you get my Data?',
}

export enum E_ClusteringSentiments {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
}

export enum E_ClusteringSubcategories {
  NOT_ANGRY = 'Not Angry',
  ANGRY = 'Angry',
}

type ClusteringOtherSubCategories = {
  subCategory?: E_ClusteringSubcategories;
};

export type ClusterInfo = {
  other?: ClusteringOtherSubCategories;
  category: E_ClusteringCategories;
  sentiment: E_ClusteringSentiments;
  confidence?: number;
};

export type ClusteringFeedData = {
  id: string;
  name: string;
  email: string;
  secondary_email?: string;
  type: ReplyType;
  rescheduled?: string;
  cluster_info: ClusterInfo;
  delivered_at: string;
};

export type ClusteringFeedDataV2 = {
  id: string;
  name: string;
  email: string;
  secondary_email?: string;
  type: ReplyType;
  rescheduled?: boolean;
  cluster_info: ClusterInfo;
  delivered_at: string;
};

export type AIClusteringConflictCheck = {
  human: ClusterInfo | string;
  ai: ClusterInfo | string;
};

export type CopilotHint = {
  clusterInfo: string | ClusterInfo;
  confidence: number;
};

export type CopilotHintResult = {
  error: boolean;
  message: string;
  data?: CopilotHint | null;
};

export const aiSentimentToClusterInfo = new Map<
  ClusteringAISentiments,
  ClusterInfo
>([
  [
    ClusteringAISentiments.MEETING_REQUEST,
    {
      sentiment: E_ClusteringSentiments.POSITIVE,
      category: E_ClusteringCategories.MEETING_REQUEST,
    },
  ],
  [
    ClusteringAISentiments.REFERRAL_IN_EMAIL,
    {
      sentiment: E_ClusteringSentiments.NEUTRAL,
      category: E_ClusteringCategories.REFERRAL_IN_EMAIL,
    },
  ],
  [
    ClusteringAISentiments.REFERRAL_IN_CC,
    {
      sentiment: E_ClusteringSentiments.NEUTRAL,
      category: E_ClusteringCategories.REFERRAL_IN_CC,
    },
  ],
  [
    ClusteringAISentiments.HAS_A_QUESTION,
    {
      sentiment: E_ClusteringSentiments.NEUTRAL,
      category: E_ClusteringCategories.HAS_A_QUESTION,
    },
  ],
  [
    ClusteringAISentiments.CONTACT_LATER,
    {
      sentiment: E_ClusteringSentiments.NEUTRAL,
      category: E_ClusteringCategories.CONTACT_LATER,
    },
  ],
  [
    ClusteringAISentiments.NOT_RELEVANT_WITH_REASON,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.NOT_RELEVANT_WITH_REASON,
    },
  ],
  [
    ClusteringAISentiments.NOT_RELEVANT_WITHOUT_REASON,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.NOT_RELEVANT_WITHOUT_REASON,
    },
  ],
  [
    ClusteringAISentiments.OTHER_SOLUTION_IN_PLACE,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.OTHER_SOLUTION_IN_PLACE,
    },
  ],
  [
    ClusteringAISentiments.PLEASE_DELETE_MY_DATA_ANGRY,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.PLEASE_DELETE_MY_DATA,
      other: {
        subCategory: E_ClusteringSubcategories.ANGRY,
      },
    },
  ],
  [
    ClusteringAISentiments.PLEASE_DELETE_MY_DATA_NOT_ANGRY,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.PLEASE_DELETE_MY_DATA,
      other: {
        subCategory: E_ClusteringSubcategories.NOT_ANGRY,
      },
    },
  ],
  [
    ClusteringAISentiments.WHERE_DID_YOU_GET_MY_DATA_ANGRY,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.WHERE_DID_YOU_GET_MY_DATA,
      other: {
        subCategory: E_ClusteringSubcategories.ANGRY,
      },
    },
  ],
  [
    ClusteringAISentiments.WHERE_DID_YOU_GET_MY_DATA_NOT_ANGRY,
    {
      sentiment: E_ClusteringSentiments.NEGATIVE,
      category: E_ClusteringCategories.WHERE_DID_YOU_GET_MY_DATA,
      other: {
        subCategory: E_ClusteringSubcategories.NOT_ANGRY,
      },
    },
  ],
]);
