export enum ReplyStatus {
  ASSIGNED = 'ASSIGNED',
  CLUSTERED = 'CLUSTERED',
  UNCLUSTERED = 'UNCLUSTERED',
  TRANSLATING = 'TRANSLATING',
  AUTOREPLIED = 'AUTOREPLIED',
  REPLIED = 'REPLIED',
  BOUNCED = 'BOUNCED',
}

export enum ReplyType {
  AUTOREPLY = 'AUTOREPLY',
  RESPONSE = 'RESPONSE',
  SECONDARY_RESPONSE = 'SECONDARY_RESPONSE',
}
