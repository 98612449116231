import React, { FC } from "react";
import { Input } from "../../../../../../components/Form/FormControls/Input";
import { Group } from "../../../styled";

export type ExampleResultProps = {
  percentageConfidenceLevel?: number;
  sentiment?: number;

  probablyHallucinating?: boolean;
};

export const PromptExampleResult: FC<ExampleResultProps> = ({
  percentageConfidenceLevel,
  sentiment,
  probablyHallucinating,
}) => {
  return (
    <Group>
      <Input
        label="% Confidence"
        disabled
        defaultValue={percentageConfidenceLevel}
      />
      <Input label="Sentiment" disabled defaultValue={sentiment} />
      <Input
        label="Probably Hallucinating"
        disabled
        defaultValue={probablyHallucinating ? "YES" : "NO"}
      />
    </Group>
  );
};
