import { FC, useEffect, useState } from "react";
import {
  ConfigurationBlockItemBase,
  Group,
} from "../../../../../../components/ConfigurationPanel/styled";
import React from "react";
import { TextAreaItemForm } from "../../../../../../components/ConfigurationPanel/TextAreaItemForm";
import { ExampleResultProps, PromptExampleResult } from "./PromptExampleResult";
import { DeleteButton } from "../../styled";

export type PromptExampleProps = { email: string; categorization: string };

export const PromptExample: FC<
  PromptExampleProps & { id: number; onDelete: (id: number) => void }
> = ({ email, categorization, id, onDelete }) => {
  const [parsedResult, setParsedResult] = useState<ExampleResultProps>();
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      setParsedResult(JSON.parse(categorization));
    } catch (error) {
      setError(error.message);
    }
  }, [categorization]);

  return (
    <ConfigurationBlockItemBase>
      <TextAreaItemForm
        readOnly
        minHeight="6rem"
        defaultValue={error || email}
      />

      <Group>
        <PromptExampleResult
          percentageConfidenceLevel={parsedResult?.percentageConfidenceLevel}
          sentiment={parsedResult?.sentiment}
        />
        <DeleteButton onClick={() => onDelete && onDelete(id)}>🗑️</DeleteButton>
      </Group>
    </ConfigurationBlockItemBase>
  );
};
