import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ConfigurationList } from "../../../../../../components/ConfigurationPanel/ConfigurationList";
import { TextAreaItemForm } from "../../../../../../components/ConfigurationPanel/TextAreaItemForm";
import { ContentCard } from "../../../../../../components/ContentCard";
import { Store } from "../../../../../../context/stores/copilot-settings";
import {
  ExampleResultProps,
  PromptExampleResult,
} from "../PromptSettings/PromptExampleResult";
import { PromptTestResultRes } from "../../../../../../api/clustering-ai";
import { ErrorMessage } from "../../../styled";

type TestResultProps = PromptTestResultRes["data"] & {
  error: string;
  isLoading: boolean;
};
const TestResult: FC<TestResultProps> = ({
  error,
  providerResult,
  isProbablyHallucinating,
  isLoading,
}) => {
  const [sentiment, setSentiment] = useState<ExampleResultProps>({});
  const [uiError, setUIError] = useState("");
  useEffect(() => {
    try {
      if (!error) {
        if (providerResult?.sentiment) {
          const s: ExampleResultProps = JSON.parse(providerResult?.sentiment);
          if (
            typeof s.sentiment === "number" &&
            typeof s.percentageConfidenceLevel === "number"
          ) {
            setSentiment(s);
            setUIError("");
          } else {
            setUIError(
              `Prompt Response is Invalid. RawResult  ${providerResult.sentiment} `
            );
          }
        }
      } else {
        setUIError(error);
      }
    } catch (err) {
      setUIError(
        `Prompt Response is Invalid. Response Not Valid JSON RawResult ${providerResult?.sentiment}. Error: ${err.message}`
      );
    }
  }, [error, providerResult]);
  return isLoading ? (
    <h5>Testing...</h5>
  ) : uiError ? (
    <ErrorMessage>{uiError}</ErrorMessage>

  ) : providerResult ? (
    <PromptExampleResult {...sentiment} probablyHallucinating={isProbablyHallucinating === true}/>
  ) : null;
};

export const PromptTestingContent = () => {
  const { store, effects } = Store.useCopilotSettingsStore();
  const [state] = store;

  const testPrompt = useCallback(
    (text: string) => {
      effects.testPrompt(text);
    },
    [state.fullPrompt, effects.testPrompt]
  );

  const testResult: TestResultProps = useMemo(
    () => ({
      ...(state.promptTestingResult || {}),
      ...state.promptTestingResultStatus,
    }),
    [state.promptTestingResult, state.promptTestingResultStatus]
  );

  return (
    <ConfigurationList>
      <TextAreaItemForm
        label="Prompt"
        minHeight="15rem"
        defaultValue={state.fullPrompt}
        readOnly
      />

      <TestResult {...testResult} />

      <TextAreaItemForm
        label="Enter a sample reply"
        minHeight="3rem"
        name="email"
        defaultValue=""
        onSubmit={testPrompt}
      />
    </ConfigurationList>
  );
};

export const PromptTesting = () => {
  const content = useMemo(
    () => ({
      header: { title: "Test Prompt" },
      body: () => <PromptTestingContent />,
    }),
    []
  );

  return <ContentCard isLoading={false} data={content} />;
};
