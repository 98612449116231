import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import ChevronDownIcon from "../../assets/chevron-down.svg";
import ChevronUpIcon from "../../assets/chevron-up.svg";
import CalendarIcon from "../../assets/calendar.svg";
import ForwardArrow from "../../assets/arrow-right.svg";

import { ReplyType } from "../../../../main-service/src/types/shared/replies";
import { SmartDateTime } from "../../components/DateTime/SmartDateTime";
import { FeedResponse, FeedContext } from "../../context/feed";
import { FilterOption } from "../../hooks/filter";
import { FeedCellLarge, FeedCellMedium, AnchorLink } from "./styled";
import {
  E_ClusteringCategories,
  E_ClusteringSentiments,
} from "../../../../main-service/src/types/shared/clustering";

export const useColumns = () => {
  const navigate = useNavigate();

  const columns = new Map([
    [
      "CLIENT",
      {
        index: "name",
        render: (data: FeedResponse["data"][0]) => {
          return (
            <FeedCellLarge className={data.cluster_info ? "" : "bold"}>
              {data.name}
            </FeedCellLarge>
          );
        },
      },
    ],
    [
      "EMAIL",
      {
        index: "email",
        render: (data: FeedResponse["data"][0]) => {
          return (
            <FeedCellMedium>
              {data.email}{" "}
              {data?.secondary_email ? (
                <>
                  <br />
                </>
              ) : (
                ""
              )}{" "}
              {data.secondary_email}
            </FeedCellMedium>
          );
        },
      },
    ],
    [
      "REPLY KIND",
      {
        index: "type",
        render: (data: FeedResponse["data"][0]) => {
          return <FeedCellMedium>{data.type || "-"}</FeedCellMedium>;
        },
      },
    ],
    [
      "REPLY TYPE",
      {
        index: "cluster_info.sentiment",
        render: (data: FeedResponse["data"][0]) => {
          return (
            <FeedCellMedium>
              {data.cluster_info?.sentiment || "-"}
            </FeedCellMedium>
          );
        },
      },
    ],
    [
      "CATEGORY",
      {
        index: "cluster_info.category",
        render: (data: FeedResponse["data"][0]) => {
          return (
            <FeedCellMedium>
              {data.cluster_info?.category || "-"}
            </FeedCellMedium>
          );
        },
      },
    ],

    [
      "RECEIVED DATE",
      {
        index: "delivered_at",
        render: (data: FeedResponse["data"][0]) => {
          // TODO: this should be removed when the smtp and imap service is done
          const date = new Date(data.delivered_at);
          date.setHours(date.getHours() - 2);

          return (
            <FeedCellMedium>
              <SmartDateTime
                timestamp={date.toISOString()}
                timeClassName="light"
              />
            </FeedCellMedium>
          );
        },
      },
    ],
    [
      "actions",
      [
        {
          label: "View",
          render: (data: FeedResponse["data"][0]) => {
            let shouldEnable = false;

            if (data.cluster_info) {
              shouldEnable = true;
            } else if (data.rescheduled) {
              shouldEnable = true;
            }
            return (
              <AnchorLink
                href={shouldEnable ? `/clustering/clustered/${data.id}` : "#"}
                className={shouldEnable ? "" : "disabled"}
                target={shouldEnable ? "_blank" : "_self"}
                onClick={(e) => {
                  if (!shouldEnable) {
                    e.preventDefault();
                  }
                }}
              >
                <ForwardArrow />
              </AnchorLink>
            );
          },
        },
      ],
    ],
  ]);

  const getColumns = useCallback(() => columns, []);

  return getColumns;
};

export const useFilterOptions = (): (() => FilterOption[]) => {
  const { clients } = useContext(FeedContext);

  const options: FilterOption[] = [
    {
      placeholder: "Filter By Client",
      renderIcon: (collapsed?: boolean) =>
        collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />,
      label: "Client",
      type: "multiselect",
      searchable: true,
      name: "client",
      getOptionValues: () => {
        return clients;
      },
    },
    {
      placeholder: "Reply Type",
      renderIcon: (collapsed?: boolean) =>
        collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />,
      label: "Reply Type",
      type: "multiselect",
      name: "reply_type",
      getOptionValues: () => {
        return Object.values(E_ClusteringSentiments);
      },
    },
    {
      placeholder: "Category",
      renderIcon: (collapsed?: boolean) =>
        collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />,
      label: "Category",
      type: "multiselect",
      name: "category",
      getOptionValues: () => {
        return Object.values(E_ClusteringCategories);
      },
    },
    {
      placeholder: "Reply Kind",
      renderIcon: (collapsed?: boolean) =>
        collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />,
      label: "Reply Kind",
      type: "multiselect",
      name: "type",
      getOptionValues: () => {
        return Object.values(ReplyType);
      },
    },
    {
      placeholder: "Received Date",
      renderIcon: () => <CalendarIcon />,
      label: "Received Date",
      type: "calendar",
      name: "received_date",
    },
  ];

  const getOptions = useCallback(() => options, [clients]);

  return getOptions;
};
