import { DB, E_Schemas, E_Tables } from '../db';
import { BaseClusteringTaskPayload } from '../internal/result';
import { ClusterInfo } from './clustering';

export enum ReplyStatus {
  ASSIGNED = 'ASSIGNED',
  CLUSTERED = 'CLUSTERED',
  UNCLUSTERED = 'UNCLUSTERED',
  TRANSLATING = 'TRANSLATING',
  AUTOREPLIED = 'AUTOREPLIED',
  REPLIED = 'REPLIED',
  BOUNCED = 'BOUNCED',
}

export enum ReplyLocks {
  LOCKED_FOR_SET_AS_AUTOREPLY = 'LOCKED_FOR_SET_AS_AUTOREPLY',
  LOCKED_FOR_SET_SEND_AFTER_DATE = 'LOCKED_FOR_SET_SEND_AFTER_DATE',
  LOCKED_FOR_SET_REPLY_TO_REPLIED = 'LOCKED_FOR_SET_REPLY_TO_REPLIED',
  LOCKED_FOR_SET_REPLY_TO_BOUNCED = 'LOCKED_FOR_SET_REPLY_TO_BOUNCED',
}

export enum ReplyType {
  AUTOREPLY = 'AUTOREPLY',
  RESPONSE = 'RESPONSE',
  SECONDARY_RESPONSE = 'SECONDARY_RESPONSE',
}

export type WpSnippets = {
  snippet1: string;
  snippet2: string;
  snippet3: string;
  snippet4: string;
  snippet5: string;
  snippet6: string;
  snippet7: string;
  snippet8: string;
  snippet9: string;
  snippet10: string;
  snippet11: string;
  snippet12: string;
  snippet13: string;
  snippet14: string;
  snippet15: string;
};

export type SecondaryProspect = {
  id: number;
  email: string;
};

export enum WoodpeckerHookEvent {
  PROSPECT_AUTOREPLIED = 'prospect_autoreplied',
  PROSPECT_BLACKLISTED = 'prospect_blacklisted',
  PROSPECT_INVALID = 'prospect_invalid',
  PROSPECT_OPTOUT = 'prospect_opt_out',
  PROSPECT_REPLIED = 'prospect_replied',
  PROSPECT_SAVED = 'prospect_saved',
  SECONDARY_REPLIED = 'secondary_replied',
}

export enum WoodpeckerAgencyHookEvent {
  COMPANY_CREATED = 'company_created',
}

export type ReplyUserAction = {
  id: string;
  user_id: string;
  name: string;
  picture: string;
  created_at: string;
  reply_id: string;
  rescheduled?: string;
  send_after?: string;
  cluster_info?: ClusterInfo;
};

export enum CampaignProspectStatus {
  PAUSED = 'PAUSED',
  INVALID = 'INVALID',
  TO_CHECK = 'TO-CHECK',
  BLACKLIST = 'BLACKLIST',
  BOUNCED = 'BOUNCED',
  ACTIVE = 'ACTIVE',
  NON_RESPONSIVE = 'NON_RESPONSIVE',
  REPLIED = 'REPLIED',
  AUTOREPLIED = 'AUTOREPLIED',
  TO_REVIEW = 'TO-REVIEW',
  OPTOUT = 'OPT_OUT',
}

export enum CampaignInterest {
  NOT_MARKED = 'NOT_MARKED',
  MAYBE_LATER = 'MAYBE_LATER',
  NOT_INTERESTED = 'NOT_INTERESTED',
  INTERESTED = 'INTERESTED',
}

export enum CampaignStatus {
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  RUNNING = 'RUNNING',
  EDITED = 'EDITED',
  STOPPED = 'STOPPED',
}

export enum Role {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  CS = 'CS',
  DEFAULT = 'DEFAULT',
}

export enum EventNames {
  RELEASE = 'RELEASE',
  LOG_REPLY_ACTION = 'LOG_REPLY_ACTION',
}

export enum ClusteringTasks {
  CLUSTERING = 'clustering',
  RECLUSTERING = 'RECLUSTERING',
  RESCHEDULING = 'RESCHEDULING',
  ERROR_RESOLUTION = 'ERROR_RESOLUTION',
}

export type SetSendAfterTaskPayload = BaseClusteringTaskPayload & {
  sendAfter: string;
};

type ReplyOrWPReply =
  | DB[E_Schemas.PUBLIC][E_Tables.WP_REPLIES]
  | DB[E_Schemas.PUBLIC][E_Tables.REPLIES];
export type ClusteringError = DB[E_Schemas.PUBLIC][E_Tables.CLUSTERING_ERRORS];

export type ReplyWithMetadata = ReplyOrWPReply & {
  sender?: Pick<DB[E_Schemas.PUBLIC][E_Tables.WP_PROSPECTS], 'email'> &
    DB[E_Schemas.PUBLIC][E_Tables.REPLIES]['sender'] & { companyName: string };
  reply_user_actions?: Array<ReplyUserAction>;
  wp_message?: string;
  translated?: string;
  clusteringError?: ClusteringError | null;
  replied_at?: string;
};

export type User = {
  id: string;
  email: string;
  name: string;
  given_name: string;
  picture: string;
  role: Role;
  is_active: boolean;
  created_at: string;
  updated_at: string;
};
